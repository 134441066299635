import { IconButton } from '@/Components/Buttons'
import { cn } from '@/Lib'
import { ReactNode } from 'react'
import { toast as toastPrimitive } from 'sonner'

type ToastProps = {
  heading: string
  description?: string
  icon?: ReactNode
}

export function toast({ heading, description, icon }: ToastProps) {
  toastPrimitive.custom((id) => toaster(id, { heading, description, icon }))
}

function toaster(id: string | number, options: ToastProps) {
  return (
    <div className='relative flex w-full flex-col gap-2 rounded-card bg-white p-4 pr-9 shadow-lg sm:w-[var(--width)]'>
      <div className='flex items-center gap-2'>
        {options.icon && options.icon}
        <p className='text-strong'>{options.heading}</p>
      </div>
      {options.description && (
        <p className={cn('text-sm text-medium', options.icon && 'pl-8')}>
          {options.description}
        </p>
      )}
      <IconButton
        className='absolute right-4 top-4'
        onClick={() => toastPrimitive.dismiss(id)}
      >
        close
      </IconButton>
    </div>
  )
}
