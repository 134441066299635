import { Icon } from '@/Components/Icon'
import { toast } from '@/Components/Toast'
import { cn } from '@/Lib'
import type { ButtonHTMLAttributes } from 'react'
import { forwardRef, useEffect, useState } from 'react'

interface CopyOrShareProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  shareData?: ShareData
  copyContent: string
}

export const CopyOrShare = forwardRef<HTMLButtonElement, CopyOrShareProps>(
  ({ className, shareData, copyContent, ...props }, ref) => {
    const [features, setFeatures] = useState({
      clipboard: false,
      share: false
    })

    useEffect(() => {
      if (
        typeof navigator !== 'undefined' &&
        typeof navigator.clipboard !== 'undefined'
      ) {
        setFeatures((prev) => ({ ...prev, clipboard: true }))
      }
      if (
        typeof navigator !== 'undefined' &&
        typeof navigator.share !== 'undefined' &&
        typeof shareData !== 'undefined'
      ) {
        setFeatures((prev) => ({ ...prev, share: true }))
      }
    }, [shareData])

    return (
      <button
        ref={ref}
        onClick={() => {
          if (features.share) {
            navigator.share(shareData).then(
              () => {},
              () => {}
            )
          } else if (features.clipboard) {
            navigator.clipboard.writeText(copyContent).then(
              () => {
                toast({
                  heading: 'Copied to clipboard.',
                  icon: <Icon className='text-green-600'>check_small</Icon>
                })
              },
              () => {}
            )
          }
        }}
        className={cn(className)}
        {...props}
      >
        {props.children && props.children}
        {features.share ? (
          <Icon>share</Icon>
        ) : features.clipboard ? (
          <Icon>content_copy</Icon>
        ) : (
          ''
        )}
      </button>
    )
  }
)

CopyOrShare.displayName = 'CopyOrShare'
